<template>
  <footer class="footer-dark bg-dark-gray pt-1 pb-2 lg-pb-35px">
    <div class="container">
      <div
        class="row justify-content-center fs-17 fw-300 mt-5 mb-4 md-mt-45px md-mb-45px xs-mt-35px xs-mb-35px"
      >
        <!-- start footer column -->
        <div
          class="col-6 col-lg-4 order-sm-1 md-mb-40px xs-mb-30px last-paragraph-no-margin"
        >
          <a
            href="demo-accounting.html"
            class="footer-logo mb-15px d-inline-block"
            ><img src="../assets/logo-aia.png" alt=""
          /></a>
          <p class="w-85 xl-w-95 sm-w-100">
            Rua Forte da Ribeira, 350 - Condominio São Gabriel I, Galpão 5 - Jd:
            São Lourenço São Paulo/SP - CEP 08340-145
          </p>
          <div class="elements-social social-icon-style-02 mt-20px lg-mt-20px">
            <ul class="small-icon light">
              <li>
                <a
                  class="facebook"
                  href="https://www.facebook.com/"
                  target="_blank"
                  ><i class="fa-brands fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a
                  class="dribbble"
                  href="http://www.dribbble.com"
                  target="_blank"
                  ><i class="fa-brands fa-dribbble"></i
                ></a>
              </li>
              <li>
                <a class="twitter" href="http://www.twitter.com" target="_blank"
                  ><i class="fa-brands fa-twitter"></i
                ></a>
              </li>
              <li>
                <a
                  class="instagram"
                  href="http://www.instagram.com"
                  target="_blank"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <!-- end footer column -->
        <!-- start footer column -->

        <!-- end footer column -->
        <!-- start footer column -->

        <!-- end footer column -->
        <!-- start footer column -->
        <div class="col-6 col-lg-4 col-sm-4 xs-mb-30px order-sm-5 offset-lg-4">
          <span class="fs-18 fw-400 d-block text-white mb-5px"
            >Fale conosco</span
          >
          <p class="mb-5px">Precisa de ajuda?</p>
          <a
            href="mailto:contato@aiaembalagens.com.br"
            class="text-white lh-16 d-block mb-15px"
            >contato@aiaembalagens.com.br</a
          >
          <p class="mb-5px">Atendimento ao cliente</p>
          <a href="tel:551120247061" class="text-white lh-16 d-block"
            >(11) 2024-7061</a
          >
          <a
            href="https://api.whatsapp.com/send?1=pt_BR&phone=5511941443031"
            class="text-white lh-16 d-block mt-5"
          >
            (11) 94144-3031 (WhatsApp)</a
          >
        </div>
        <!-- end footer column -->
      </div>
      <div class="row align-items-center fs-16 fw-300">
        <!-- start copyright -->
        <div
          class="col-md-12 last-paragraph-no-margin order-2 order-md-1 text-center"
        >
          <p>&COPY; Copyright 2024 Aia Embalagens</p>
        </div>
        <!-- end copyright -->
        <!-- start footer menu -->

        <!-- end footer menu -->
      </div>
    </div>
  </footer>
  <div class="scroll-progress d-none d-xxl-block">
    <a href="#" class="scroll-top" aria-label="scroll">
      <span class="scroll-text">Scroll</span
      ><span class="scroll-line"><span class="scroll-point"></span></span>
    </a>
  </div>
  <a
    @click="whatsAppClick()"
    href="https://api.whatsapp.com/send?1=pt_BR&amp;phone=5511941443031"
    id="whatsapp"
    target="_blank"
    style="width: 40px"
  >
    <span>Precisando de ajuda?<br />Fale conosco pelo Whatsapp!</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      fill="#25D366"
    >
      <path
        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
      />
    </svg>
  </a>
</template>
<script>
export default {
  name: 'AppFooter',
  data() {
    return {};
  },
  methods: {
    whatsAppClick() {
      this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Whatsapp',
        action: 'click_whatsapp',
        label: 'Whatsapp Click',
        value: 1,
      });
    },
  },
};
</script>

<style>
#whatsapp {
  border-radius: 50%;
  bottom: 100px;
  color: #fff;
  font-size: 32px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 35px;
  text-align: center;
  width: 50px;
  z-index: 100;
}
#whatsapp > span {
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  width: 200px;
  right: calc(100% + 10px);
  background: #f6e7b3;
  color: #666;
  padding: 10px;
  border-radius: 5px;
  top: calc(50% - 17px);
}
</style>
