const scriptLoaderMixin = {
  mounted() {
    setTimeout(() => {
      this.loadScripts();
    }, 100);
  },
  methods: {
    loadScripts() {
      const scripts = [
        '/assets/js/jquery.js',
        '/assets/js/vendors.js',
        '/assets/js/main.js',
      ];

      // Função auxiliar para carregar cada script
      const loadScript = (src) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = src;
          script.async = true;

          script.onload = () => {
            resolve();
          };

          script.onerror = () => {
            reject();
          };

          document.head.appendChild(script);
        });
      };

      // Carregando cada script em sequência
      scripts.reduce((chain, script) => {
        return chain.then(() => loadScript(script));
      }, Promise.resolve());
    },
  },
};

export default scriptLoaderMixin;