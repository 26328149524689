import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head';
import scriptLoaderMixin from '@/mixins/scriptLoaderMixin';
import VueTheMask from 'vue-the-mask'
import { createGtm } from '@gtm-support/vue-gtm';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';

// import 'jquery';

import '@/assets/css/vendors.css'
import '@/assets/css/icon.min.css'
import '@/assets/css/style.css'
import '@/assets/css/responsive.css'
import '@/assets/demos/yoga-and-meditation/yoga-and-meditation.css'

import EmailJS from 'emailjs-com';
EmailJS.init('sDT_uLy2RjaHXFTvY');

const head = createHead();
createApp(App)
// .mixin(scriptLoaderMixin)
.use(head).use(router).use(VueTheMask).use(createGtm({
    id: 'GTM-WL38FP7M',
    debug: true,
    vueRouter: router,
    loadScript: true,
})).mount('#app')
