<template>
  <header class="header-with-topbar" id="topo">
    <!-- start navigation -->
    <nav
      class="navbar navbar-expand-lg header-light bg-white responsive-sticky"
    >
      <div class="container-fluid">
        <div class="col-auto col-lg-2 me-lg-0 me-auto">
          <router-link class="navbar-brand" to="/" @click="scrollToTop">
            <img src="../assets/logo-aia.png" alt="" class="default-logo" />
            <img src="../assets/logo-aia.png" alt="" class="alt-logo" />
            <img src="../assets/logo-aia.png" alt="" class="mobile-logo" />
          </router-link>
        </div>
        <div class="col-auto menu-order position-static">
          <button
            class="navbar-toggler float-start"
            id="closeNav"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-line"></span>
            <span class="navbar-toggler-line"></span>
            <span class="navbar-toggler-line"></span>
            <span class="navbar-toggler-line"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav fw-600">
              <li class="nav-item" @click="scrollToTop">
                <router-link to="/" class="nav-link">Inicio</router-link>
              </li>
              <li class="nav-item" @click="scrollToTop">
                <router-link to="/sobre-nos" class="nav-link"
                  >Quem somos</router-link
                >
              </li>
              <li class="nav-item" @click="scrollToTop">
                <router-link to="/catalogo" class="nav-link"
                  >Catalogo</router-link
                >
              </li>
              <li class="nav-item" @click="scrollToTop">
                <router-link to="/contato" class="nav-link"
                  >Contato</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-auto col-lg-2 text-end d-none d-sm-flex">
          <div class="header-icon">
            <div class="header-social-icon icon">
              <a href="https://www.facebook.com/aiaembalagens" target="_blank"
                ><i class="fa-brands fa-facebook-f"></i
              ></a>
              <a href="https://www.instagram.com/aiaembalagens" target="_blank"
                ><i class="fa-brands fa-instagram"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- end navigation -->
  </header>
</template>
<script>
export default {
  name: 'AppNavbar',
  data() {
    return {};
  },
  methods: {
    scrollToTop() {
      const navbarNav = document.getElementById('navbarNav');
      const closeNavButton = document.getElementById('closeNav');
      const isVisible =
        closeNavButton.offsetWidth > 0 || closeNavButton.offsetHeight > 0;
      3;

      if (isVisible) {
        // closeNavButton.click();
        navbarNav.classList.remove('show');
      }
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Para rolar suavemente
        });
      }, 300);
    },
  },
};
</script>

<style></style>
import { log10 } from 'core-js/core/number';
