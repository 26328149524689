<template>
  <AppNavbar></AppNavbar>
  <router-view />
  <AppFooter></AppFooter>
</template>
<script>
import { useHead } from '@vueuse/head';
import { computed, reactive } from 'vue';
import AppNavbar from './components/AppNavbar.vue';
import AppFooter from './components/AppFooter.vue';
export default {
  setup() {
    const siteData = reactive({
      title: 'AIA Embalagens',
      description: 'AIA Embalagens',
    });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
      ],
    });
  },
  components: {
    AppNavbar,
    AppFooter,
  },
};
</script>
<style></style>
