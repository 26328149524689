import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sobre-nos',
    name: 'sobre-nos',
    component: () => import(/* webpackChunkName: "sobre-nos" */ '../views/AboutView.vue')
  },
  {
    path: '/catalogo',
    name: 'catalogo',
    component: () => import(/* webpackChunkName: "catalog" */ '../views/CatalogView.vue')
  },
  {
    path: '/contato',
    name: 'contato',
    component: () => import(/* webpackChunkName: "contato" */ '../views/ContactView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
