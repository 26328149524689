<template>
  <AppBanner />
  <HomeSection1 />
  <HomeSection2 />
  <HomeContact />
</template>

<script>
import AppBanner from '@/components/home/AppBanner.vue';
import HomeSection1 from '@/components/home/HomeSection1.vue';
import HomeSection2 from '@/components/home/HomeSection2.vue';
import HomeContact from '@/components/home/HomeContact.vue';
import scriptLoaderMixin from '@/mixins/scriptLoaderMixin';
export default {
  name: 'HomeView',
  mixins: [scriptLoaderMixin],
  mounted() {
    setTimeout(() => {
      // this.loadScripts();
    }, 100);
  },
  components: {
    AppBanner,
    HomeSection1,
    HomeSection2,
    HomeContact,
  },
};
</script>
