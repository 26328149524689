<template>
  <section
    class="top-space-margin p-0 full-screen md-h-600px sm-h-500px section-dark"
    data-parallax-background-ratio="0.8"
    style="background-image: url('/assets/images/banner-aia.jpg')"
  >
    <div class="container h-100">
      <div class="row align-items-center h-100">
        <div
          class="col-xl-7 col-md-9 col-sm-9 position-relative text-white"
          data-anime='{ "el": "childs", "opacity": [0, 1], "translateY": [30, 0], "staggervalue": 200, "easing": "easeInOutSine" }'
        >
          <div
            class="fs-80 lh-75 sm-fs-65 fw-600 mb-20px text-shadow-large ls-minus-2px"
          >
            AIA Embalagens
          </div>
          <div>
            <span class="fs-20 w-70 md-w-85 mb-25px fw-300 d-inline-block">
              a embalagem que valoriza o seu produto
            </span>
          </div>
          <div class="icon-with-text-style-08">
            <div class="feature-box feature-box-left-icon-middle">
              <div
                class="feature-box-icon feature-box-icon-rounded w-65px h-65px rounded-circle bg-yellow me-15px rounded-box"
              >
                <i
                  class="feather icon-feather-arrow-right text-dark-gray icon-extra-medium"
                ></i>
              </div>
              <div class="feature-box-content">
                <a
                  href="#section1"
                  class="d-inline-block fs-19 text-white text-shadow-double-large"
                  >Conheça nossos produtos</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AppBanner',
  data() {
    return {};
  },
};
</script>

<style></style>
