<template>
  <section class="bg-very-light-gray">
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-lg-7 text-center mb-2"
          data-anime='{ "el": "childs", "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 100, "easing": "easeOutQuad" }'
        >
          <!-- <span
            class="fw-600 ls-1px fs-16 alt-font d-inline-block text-uppercase mb-5px text-base-color"
            >Feel free to get in touch!</span
          > -->
          <h2 class="alt-font text-dark-gray fw-600 ls-minus-2px">
            Como podemos te ajudar?
          </h2>
        </div>
      </div>
      <div
        class="row row-cols-md-1 justify-content-center"
        data-anime='{ "translateY": [100, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 100, "easing": "easeOutQuad" }'
      >
        <div class="col-xl-9 col-lg-11">
          <!-- start contact form -->
          <form method="post" class="contact-form-style-03" @submit="sendEmail">
            <div
              class="row justify-content-center"
              data-anime='{ "opacity": [0,1], "duration": 600, "delay":0, "staggervalue": 300, "easing": "easeOutQuad" }'
            >
              <div class="col-md-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-600 text-dark-gray mb-0"
                  >Nome*</label
                >
                <div class="position-relative form-group mb-25px">
                  <span class="form-icon"
                    ><i class="bi bi-emoji-smile"></i
                  ></span>
                  <input
                    class="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                    id="exampleInputEmail1"
                    type="text"
                    name="name"
                    required
                    v-model="nome"
                    placeholder="Qual é o seu nome?"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-600 text-dark-gray mb-0"
                  >Email*</label
                >
                <div class="position-relative form-group mb-25px">
                  <span class="form-icon"><i class="bi bi-envelope"></i></span>
                  <input
                    class="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                    id="exampleInputEmail2"
                    type="email"
                    name="email"
                    v-model="email"
                    required
                    placeholder="Insira o seu endereço de email"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-600 text-dark-gray mb-0"
                  >Telefone</label
                >
                <div class="position-relative form-group mb-25px">
                  <span class="form-icon"><i class="bi bi-telephone"></i></span>
                  <input
                    class="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                    id="exampleInputEmail3"
                    type="tel"
                    name="phone"
                    v-model="telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    required
                    placeholder="Digite seu número de telefone"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-600 text-dark-gray mb-0"
                  >Assunto</label
                >
                <div class="position-relative form-group mb-25px">
                  <span class="form-icon"><i class="bi bi-journals"></i></span>
                  <input
                    class="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                    id="exampleInputEmail4"
                    type="text"
                    name="subject"
                    v-model="assunto"
                    required
                    placeholder="Como podemos te ajudar?"
                  />
                </div>
              </div>
              <div class="col-12 mb-4">
                <label
                  for="exampleInputEmail1"
                  class="form-label fw-600 text-dark-gray mb-0"
                  >Mensagem</label
                >
                <div class="position-relative form-group form-textarea mb-0">
                  <textarea
                    class="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                    name="comment"
                    v-model="mensagem"
                    required
                    placeholder="Descreva sobre como podemos te ajudar"
                    rows="4"
                  ></textarea>
                  <span class="form-icon"
                    ><i class="bi bi-chat-square-dots"></i
                  ></span>
                </div>
              </div>
              <div class="col-xxl-6 col-lg-7 col-md-8">
                <p class="mb-0 fs-14 lh-24 text-center text-md-start">
                  Temos o compromisso de proteger sua privacidade. Nós nunca
                  iremos coletar informações sobre você sem o seu consentimento
                  explícito.
                </p>
              </div>
              <div
                class="col-xxl-6 col-lg-5 col-md-4 text-center text-md-end sm-mt-25px"
              >
                <div v-if="enviado">
                  <div class="alert alert-success alert-dismissable">
                    <strong>Sucesso!</strong> Mensagem enviada.
                  </div>
                </div>
                <div v-else>
                  <button
                    class="btn btn-medium btn-dark-gray btn-box-shadow btn-round-edge text-transform-none primary-font submit"
                    type="submit"
                    v-on:click.prevent="sendEmail"
                    v-if="!loading"
                  >
                    Enviar
                  </button>
                  <button
                    disabled
                    class="btn btn-medium btn-dark-gray btn-box-shadow btn-round-edge text-transform-none primary-font"
                    v-else
                  >
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="col-12">
                <div class="form-results mt-20px d-none"></div>
              </div>
            </div>
          </form>
          <!-- end contact form -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import EmailJS from 'emailjs-com';
export default {
  name: 'HomeContact',
  data() {
    return {
      loading: false,
      enviado: false,
      nome: '',
      email: '',
      mensagem: '',
      telefone: '',
      assunto: '',
    };
  },
  methods: {
    sendEmail() {
      console.log('sendEmail');

      const templateId = 'template_yp2dhab';
      const params = {
        nome: this.nome,
        email: this.email,
        telefone: this.telefone,
        mensagem: this.mensagem,
        assunto: this.assunto,
      };
      const hasEmptyField = Object.values(params).some(
        (value) => value === '' || value === null
      );

      if (hasEmptyField) {
        return false;
      }
      this.loading = true;

      EmailJS.send('service_d5xn88p', templateId, params)
        .then((response) => {
          console.log(
            'Email enviado com sucesso!',
            response.status,
            response.text
          );
          this.resetForm();
          this.enviado = true;
        })
        .catch((error) => {
          console.error('Falha ao enviar email:', error);
          this.enviado = true;
        });
    },
    resetForm() {
      this.nome = '';
      this.email = '';
      this.mensagem = '';
      this.telefone = '';
      this.assunto = '';
    },
  },
};
</script>

<style></style>
