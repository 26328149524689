<template>
  <section
    id="section1"
    class="bg-very-light-gray overlap-height position-relative background-position-center background-repeat"
    style="
      background-image: url('/assets/images/vertical-center-line-bg-dark.svg');
    "
  >
    <div class="container overlap-gap-section">
      <div class="row justify-content-center mb-5 xs-mb-7">
        <div
          class="col-md-8 text-center"
          data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
        >
          <h2 class="alt-font mb-5px text-dark-gray ls-minus-2px">
            Nossos Produtos
          </h2>
          <span class="d-inline-block"
            >Conheça alguns dos nossos produtos abaixo, ou se preferir baixa
            nossos catálogos completos e atualizados
          </span>
        </div>
      </div>
      <div
        class="row row-cols-1 row-cols-lg-4 row-cols-md-2 justify-content-center"
        data-anime='{ "el": "childs", "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
      >
        <div
          class="col text-center rotate-box-style-01 lg-mb-45px"
          data-bottom-top="transform: translateY(80px)"
          data-top-bottom="transform: translateY(-80px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="background-image: url('./assets/icones/1. Fundos.jpg')"
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Dumbbell align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      incolor, renda, poa, laminado entre outros
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Fundos</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01 lg-mb-45px"
          data-bottom-top="transform: translateY(-40px)"
          data-top-bottom="transform: translateY(40px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="
                    background-image: url('./assets/icones/2. Sacos Adesivados.jpg');
                  "
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Leafs align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      incolor, metalizado colorido, metalizado com diversas
                      estampas, entre outros
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Sacos adesivados</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01 lg-mb-45px"
          data-bottom-top="transform: translateY(80px)"
          data-top-bottom="transform: translateY(-80px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="
                    background-image: url('./assets/icones/3. Sacos Para Cesta.jpg');
                  "
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Summer align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      love, primavera, viagem, coração entre outras
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Sacos para cesta</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01 lg-mb-45px"
          data-bottom-top="transform: translateY(-40px)"
          data-top-bottom="transform: translateY(40px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="background-image: url('./assets/icones/4. Trufas.jpg')"
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Leafs align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      coloridas e diversos sabores
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Trufas</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01 md-mb-45px"
          data-bottom-top="transform: translateY(80px)"
          data-top-bottom="transform: translateY(-80px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="
                    background-image: url('./assets/icones/5. Sacos para Presente.jpg');
                  "
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Swimming align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      diversas estampas no metalizado e perolado
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Sacos para presente</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01 mt-5 lg-mt-0 sm-mb-45px"
          data-bottom-top="transform: translateY(-40px)"
          data-top-bottom="transform: translateY(40px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="background-image: url('./assets/icones/6. Cones.jpg')"
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Chopsticks align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      para botão de rosas, incolor, decorado com diversas
                      estampas
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Cone</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01"
          data-bottom-top="transform: translateY(80px)"
          data-top-bottom="transform: translateY(-80px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="background-image: url('./assets/icones/7. Páscoa.jpg')"
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-ICQ align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      tudo para sua pascoa, saco expresso, folha, sacola
                      surpresa entre outros
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Pascoa</span
              >
            </div>
          </div>
        </div>

        <div
          class="col text-center rotate-box-style-01 mt-5 lg-mt-0 sm-mb-45px"
          data-bottom-top="transform: translateY(-40px)"
          data-top-bottom="transform: translateY(40px)"
        >
          <div class="rm-rotate-box text-center">
            <div class="flipper to-left">
              <div class="thumb-wrap">
                <div
                  class="front overflow-hidden h-250px md-h-250px border-radius-4px overflow-hidden"
                  style="background-image: url('./assets/icones/8. Natal.jpg')"
                ></div>
                <div class="back border-radius-4px overflow-hidden">
                  <div class="box-overlay bg-base-color"></div>
                  <div
                    class="content-wrap p-40px xs-p-30px last-paragraph-no-margin"
                  >
                    <!-- <i
                      class="line-icon-Chopsticks align-middle icon-extra-large text-white mb-20px"
                    ></i> -->
                    <p
                      class="text-white opacity-7 lh-30 sm-w-70 xs-w-100 mx-auto"
                    >
                      tudo para o seu natal, embalagens para presente, para
                      panetone entre outros
                    </p>
                  </div>
                </div>
              </div>
              <span class="fs-20 d-inline-block mt-30px fw-600 text-dark-gray"
                >Natal</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AppSection1',
  data() {
    return {};
  },
};
</script>

<style></style>
